function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _require = require('preact'),
    h = _require.h;

var classNames = require('classnames');

var pure = require('../../utils/pure');

var FilePreviewAndLink = require('./FilePreviewAndLink');

var FileProgress = require('./FileProgress');

var FileInfo = require('./FileInfo');

var Buttons = require('./Buttons');

module.exports = pure(function FileItem(props) {
  var file = props.file;
  var isProcessing = file.progress.preprocess || file.progress.postprocess;
  var isUploaded = file.progress.uploadComplete && !isProcessing && !file.error;
  var uploadInProgressOrComplete = file.progress.uploadStarted || isProcessing;
  var uploadInProgress = file.progress.uploadStarted && !file.progress.uploadComplete || isProcessing;
  var isPaused = file.isPaused || false;
  var error = file.error || false;
  var showRemoveButton = props.individualCancellation ? !isUploaded : !uploadInProgress && !isUploaded;
  var dashboardItemClass = classNames('uppy-u-reset', 'uppy-DashboardItem', {
    'is-inprogress': uploadInProgress
  }, {
    'is-processing': isProcessing
  }, {
    'is-complete': isUploaded
  }, {
    'is-paused': isPaused
  }, {
    'is-error': error
  }, {
    'is-resumable': props.resumableUploads
  }, {
    'is-noIndividualCancellation': !props.individualCancellation
  });
  return h("li", {
    class: dashboardItemClass,
    id: "uppy_" + file.id
  }, h("div", {
    class: "uppy-DashboardItem-preview"
  }, h(FilePreviewAndLink, {
    file: file,
    showLinkToFileUploadResult: props.showLinkToFileUploadResult
  }), h(FileProgress, _extends({
    error: error,
    isUploaded: isUploaded
  }, props))), h("div", {
    class: "uppy-DashboardItem-fileInfoAndButtons"
  }, h(FileInfo, {
    file: file,
    id: props.id,
    acquirers: props.acquirers,
    containerWidth: props.containerWidth,
    i18n: props.i18n
  }), h(Buttons, {
    file: file,
    metaFields: props.metaFields,
    showLinkToFileUploadResult: props.showLinkToFileUploadResult,
    showRemoveButton: showRemoveButton,
    uploadInProgressOrComplete: uploadInProgressOrComplete,
    removeFile: props.removeFile,
    toggleFileCard: props.toggleFileCard,
    i18n: props.i18n,
    log: props.log,
    info: props.info
  })));
});