var _require = require('preact'),
    h = _require.h;

var getAriaLabelOfCheckbox = function getAriaLabelOfCheckbox(props) {
  if (props.type === 'folder') {
    if (props.isChecked) {
      return props.i18n('unselectAllFilesFromFolderNamed', {
        name: props.title
      });
    } else {
      return props.i18n('selectAllFilesFromFolderNamed', {
        name: props.title
      });
    }
  } else {
    if (props.isChecked) {
      return props.i18n('unselectFileNamed', {
        name: props.title
      });
    } else {
      return props.i18n('selectFileNamed', {
        name: props.title
      });
    }
  }
}; // if folder:
//   + checkbox (selects all files from folder)
//   + folder name (opens folder)
// if file:
//   + checkbox (selects file)
//   + file name (selects file)


module.exports = function (props) {
  return h("li", {
    class: props.className
  }, h("button", {
    type: "button",
    class: "uppy-u-reset uppy-ProviderBrowserItem-fakeCheckbox " + (props.isChecked ? 'uppy-ProviderBrowserItem-fakeCheckbox--is-checked' : ''),
    onClick: props.toggleCheckbox // for the <label/>
    ,
    id: props.id,
    role: "option",
    "aria-label": getAriaLabelOfCheckbox(props),
    "aria-selected": props.isChecked,
    "aria-disabled": props.isDisabled,
    "data-uppy-super-focusable": true
  }), props.type === 'file' // label for a checkbox
  ? h("label", {
    for: props.id,
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner"
  }, props.itemIconEl, props.showTitles && props.title) // button to open a folder
  : h("button", {
    type: "button",
    class: "uppy-u-reset uppy-ProviderBrowserItem-inner",
    onclick: props.handleFolderClick,
    "aria-label": props.i18n('openFolderNamed', {
      name: props.title
    })
  }, props.itemIconEl, props.showTitles && props.title));
};